<template>
  <v-chip outlined>
    <v-tooltip right>
      <template v-slot:activator="{ on }">
        <router-link :to="url" tag="a">
          <v-avatar left>
            <img :src="identicon" :alt="id" />
          </v-avatar>
        </router-link>
        <div v-on="on" @click.prevent.stop="clicked">
          <span style="font-family: monospace; text-decoration: underline;">
            {{ name ? name : long ? id : `&hellip;${id.substr(id.length - 4)}` }}
          </span>
        </div>
      </template>
      <div v-if="!long" style="font-size: 16px; font-family: monospace;">{{ id }}</div>
      <span v-if="copied">Copied!</span>
      <span v-else>Click to copy</span>
    </v-tooltip>
  </v-chip>
</template>

<script>
import { createHash } from 'crypto';
import Identicon from 'identicon.js';
import clipboard from '@/services/clipboard';

export default {
  props: {
    id: String,
    url: String,
    name: String,
    long: Boolean,
  },
  data() {
    return {
      copied: false,
    };
  },
  methods: {
    clicked() {
      clipboard(this.id);
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 2000);
    },
  },
  computed: {
    identicon() {
      return `data:image/png;base64,${new Identicon(createHash('sha256').update(this.id).digest('hex'), { background: [100, 100, 100, 50], margin: 0.2 }).toString()}`;
    },
  },
};
</script>
